.exp__nav {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    border-radius: 1rem;
  }
  
  .exp__nav a {
    background: rgba(0, 0, 0, 0.3);
    margin: 2rem;
    padding: 1rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
  }
  
  .exp__nav a:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  
  .exp__nav a.active {
    background: var(--color-primary);
    color: var(--color-white);
  }

  /* ===== MEDIA QUERIES (MEDIUM DEVICES) ===== */

@media screen and (max-width: 1024px) {
  }
  
  /* ===== MEDIA QUERIES (SMALL DEVICES) ===== */
  
  @media screen and (max-width: 600px) {
  
    .exp__nav a {
      margin: 0.5rem;
    }
  }

  /* ===== MEDIA QUERIES (VERY SMALL DEVICES) ===== */

@media screen and (max-width: 290px) {
  .exp__nav a {
    margin: 0.5rem;
  }
}
