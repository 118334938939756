.competences__container {
  display: flex;
  justify-content: center;
  gap: 4rem;
}

.competences__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition3);
}

.competences__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.competences__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.competences__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.competences__details {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.competences__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/* ===== MEDIA QUERIES (MEDIUM DEVICES) ===== */

@media screen and (max-width: 1024px) {
  .competences__container {
    flex-direction: column;
    align-items: center;
  }

  .competences__container > div {
    width: 90%;
    padding: 2rem;
  }

  .competences__content p {
    padding: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .competences__container {
    gap: 1rem;
  }

  .competences__container > div {
    width: 95%;
  }
}
