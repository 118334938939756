.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
  }
  
:root {
    --swiper-theme-color: var(--color-primary);
  }

  /* AUTRE MOYEN POUR CHANGER LA COULEUR
.swiper-button-next::after, .swiper-button-prev::after {
  color: red (Mettre !important);
} */

.swiper-button-next, .swiper-button-prev {
    top: 43% !important;
  }

@media screen and (max-width: 600px) {
  
    .swiper-button-next {
      top: 43% !important;
      right: 0% !important;
    }
  
    .swiper-button-prev {
      top: 43% !important;
      left: 0% !important;
    }
  
    .swiper-button-prev::after, .swiper-button-next::after {
      font-size: medium !important;
    }
  }