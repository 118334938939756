.container.professionnelles_container {
  width: var(--container-width-lg);
  padding-bottom: 4rem;
}

.profess { 
  min-height: 300px;
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.profess__content{
    display: flex;
  flex-direction: column;
}

.profess__title {
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1.2rem;
    width: 80%;
    margin: 0.8rem auto 0;
}

.profess__employer{
    color: var(--color-light);
    margin: 0.8rem auto 0;
}

.profess__description {
    width: 80%;
    margin: 0.8rem auto 0;
    text-align: justify;
}

/* ===== MEDIA QUERIES (MEDIUM DEVICES) ===== */

@media screen and (max-width: 1024px) {
  .container.professionnelles_container {
    width: var(--container-width-md);
    }
  }
  
  /* ===== MEDIA QUERIES (SMALL DEVICES) ===== */
  
  @media screen and (max-width: 600px) {
  .container.professionnelles_container {
    width: 97%;
    }

  .profess {
    padding: 1rem;
  }

  .profess__title {
     width: 98%;
  }
  
  .profess__employer{
      width: 98%;
  }
  
  .profess__description {
      width: var(--container-width-sm);
  }
  
    .profess__content {
      width: 100%;
    }
  }
  