.description__container {
  width: 80%;
}

.experiences {
  margin-top: 2rem;
  min-height: 300px;
  background: var(--color-bg-variant);
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding: 4rem;
  border-radius: 2rem;
  user-select: none;
  border: 1px solid transparent;
  transition: var(--transition3);
}

.experiences:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experiences > h2,
.experiences > h3 {
  text-align: center;
  margin-top: 0.8rem;
}

.experiences > small {
  text-align: center;
  color: var(--color-light);
}

h2 {
  color: var(--color-primary);
}

h3 {
  color: var(--color-light);
}

.experiences p {
  margin-top: 0.8rem;
  color: var(--color-white);
}

.experiences ul {
  padding-left: 20px;
}

.experiences ul li::before {
  margin-top: 0.8rem;
  content: "•";
  color: var(--color-white);
  font-size: 1em;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.codinGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-bg-variant);
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 2rem;
  user-select: none;
  border: 1px solid transparent;
  text-align: center;
  transition: var(--transition3);
}

.codinGame:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.btn {
  margin-top: 2rem;
}

/* ===== MEDIA QUERIES (SMALL DEVICES) ===== */

@media screen and (max-width: 600px) {
  .description__container {
    width: 95%;
  }

  .experiences {
    padding: 1rem;
  }

  .experiences > h2,
  .experiences > h3 {
    margin-top: 1rem;
  }

  .experiences p {
    margin-top: 1rem;
  }
}
